import React, { Component } from 'react';
import {
  Form,
  Input,
  Typography,
  InputNumber,
  Button,
  Icon,
  Row,
  Col,
  Divider,
} from 'antd';
import moment from 'moment';

import DateRange from './DateRange';

const { Title, Paragraph } = Typography;

class SampleLeaseForm extends Component {
  sample = {
    premise: 'Unit A,6/F, ABC Road, Hong Kong',
    borrowRate: 0.29,
    leasePeriod: {
      from: moment('2016-05-03').endOf('day'),
      to: moment('2018-04-16').endOf('day'),
    },
    segments: [
      {
        from: moment('2016-05-03').endOf('day'),
        to: moment('2016-06-02').endOf('day'),
        rent: 0,
        mgtFee: 88888,
        promotionLevy: 0,
      },
      {
        from: moment('2016-06-03').endOf('day'),
        to: moment('2017-05-02').endOf('day'),
        rent: 120000,
        mgtFee: 88888,
        promotionLevy: 0,
      },
      {
        from: moment('2017-05-03').endOf('day'),
        to: moment('2018-04-16').endOf('day'),
        rent: 144000,
        mgtFee: 88888,
        promotionLevy: 0,
      },
    ],
  };

  render = () => {
    const { sample } = this;
    // eslint-disable-next-line
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('segments', { initialValue: [0, 1, 2] });
    const segments = getFieldValue('segments');
    const segmentItems = segments.map(k => (
      <React.Fragment key={k}>
        <Col span={24}>
          <Title level={3}>{`Rental Period ${k + 1}`}</Title>
        </Col>
        <Col span={24}>
          <Form.Item label='Period'>
            {getFieldDecorator(`segmentPeriod[${k}]`, {
              initialValue: [sample.segments[k].from, sample.segments[k].to],
            })(
              <DateRange disabled />,
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label='Rent'>
            {getFieldDecorator(`rent[${k}]`, {
              initialValue: sample.segments[k].rent,
            })(
              <InputNumber disabled />,
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label='Management Fee'>
            {getFieldDecorator(`mgtFee[${k}]`, {
              initialValue: sample.segments[k].mgtFee,
            })(
              <InputNumber disabled />,
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label='Other Fixed Payment'>
            {getFieldDecorator(`promotionLevy[${k}]`, {
              initialValue: sample.segments[k].promotionLevy,
            })(
              <InputNumber disabled />,
            )}
          </Form.Item>
        </Col>
        <Divider />
      </React.Fragment>
    ));
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    const contractPeriodHelpText = (
      <Typography>
        <Paragraph type='secondary'>
          The whole period that the contract covers, including rental-free period.
        </Paragraph>
        <Paragraph type='secondary'>
          {'If there are periods with different rental, please update in "rental period".'}
        </Paragraph>
        <Paragraph type='secondary'>
          E.g. Rental Period 1, rent = 0 to reflect rental free period.
        </Paragraph>
        <Paragraph type='secondary'>
          Rental period 2, rent = 10k
        </Paragraph>
        <Paragraph type='secondary'>
          Rental period 3, rent = 20k etc.
        </Paragraph>
      </Typography>
    );
    return (
      <Form {...formItemLayout} className='lease-form'>
        <Row>
          <Title level={3}>Contract Information</Title>
          <Form.Item label='Premise' help='Please insert the address'>
            {getFieldDecorator('premise', {
              initialValue: sample.premise,
            })(
              <Input
                placeholder='premise'
                style={{ width: 500 }}
                disabled
              />,
            )}
          </Form.Item>
          <Form.Item label='Contract Period' help={contractPeriodHelpText}>
            {getFieldDecorator('leasePeriod', {
              initialValue: [sample.leasePeriod.from, sample.leasePeriod.to],
            })(
              <DateRange onChange={this.handleLeasePeriod} disabled />,
            )}
          </Form.Item>
          <Form.Item label='Monthly Discount Rate' help='Please assess yourself'>
            {getFieldDecorator('borrowRate', {
              initialValue: sample.borrowRate,
            })(
              <InputNumber
                placeholder='borrow rate'
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                disabled
              />,
            )}
          </Form.Item>
        </Row>
        <Row>
          Please provide rent information by adding each rental period
        </Row>
        <Divider />
        <Row gutter={24}>
          {segmentItems}
        </Row>
        <Row>
          <Form.Item>
            <Button type='dashed' disabled>
              <Icon type='plus' />
              Add Rental Period
            </Button>
          </Form.Item>
        </Row>
        <Divider />
      </Form>
    );
  }
}

const WrappedForm = Form.create({ name: 'lease' })(SampleLeaseForm);
export default WrappedForm;
