import moment from 'moment';

const getReportDates = (from, to) => {
  // we always report from date
  const reportDates = [moment(from)];
  const trackingDate = moment(from);
  while (trackingDate.isBefore(to)) {
    trackingDate.endOf('month');
    reportDates.push(moment(trackingDate));
    trackingDate.add(1, 'day');
  }
  return reportDates;
};

export const generatePaymentSchedule = (input, sheet) => {
  const {
    premise,
    leasePeriod,
    borrowRate,
    fiscal,
    segments,
  } = input;
  sheet.cell('A1').value('Premise').style({ bold: true });
  sheet.cell('B1').value(premise);
  sheet.cell('A2').value('Period').style({ bold: true });
  sheet.cell('B2').formula(`
  DATE(${leasePeriod.from.year()},${leasePeriod.from.month() + 1},${leasePeriod.from.date()})
  `).style('numberFormat', 'd/m/yyyy');
  sheet.cell('C2').formula(`
  DATE(${leasePeriod.to.year()},${leasePeriod.to.month() + 1},${leasePeriod.to.date()})
  `).style('numberFormat', 'd/m/yyyy');
  sheet.cell('A3').value('Number of month').style({ bold: true });
  sheet.cell('A4').value('Monthly borrowing rate').style({ bold: true });
  sheet.cell('B4').value(borrowRate / 100).style('numberFormat', '0.00%');
  sheet.cell('A5').value('Fiscal Year Support').style({ bold: true });
  sheet.cell('B5').value(fiscal);
  sheet.cell('D6').value('Rent').style({ bold: true });
  sheet.cell('E6').value('Mgt Fee').style({ bold: true });
  sheet.cell('F6').value('Other Fixed Payment').style({ bold: true });
  sheet.cell('G6').value('Total').style({ bold: true });
  segments.forEach((seg, i) => {
    sheet.cell(7 + i, 2).value(seg.from.toDate()).style('numberFormat', 'D/M/Y');
    sheet.cell(7 + i, 3).value(seg.to.toDate()).style('numberFormat', 'D/M/Y');
    const startAddr = sheet.cell(7 + i, 4).value(seg.rent).address();
    sheet.cell(7 + i, 5).value(seg.mgtFee);
    const endAddr = sheet.cell(7 + i, 6).value(seg.promotionLevy).address();
    sheet.cell(7 + i, 7).formula(`SUM(${startAddr}:${endAddr})`);
  });
  const tableHeaderRow = 9 + segments.length;
  sheet.cell(tableHeaderRow, 1).value('Date').style({ bold: true });
  sheet.cell(tableHeaderRow, 2).value('Year').style({ bold: true });
  sheet.cell(tableHeaderRow, 3).value('Payment').style({ bold: true });
  sheet.cell(tableHeaderRow, 4).value('Lease Assets-Cost').style({ bold: true });
  sheet.cell(tableHeaderRow, 5).value('Lease Assets-Dep').style({ bold: true });
  sheet.cell(tableHeaderRow, 6).value('Lease Assers-NBV').style({ bold: true });
  sheet.cell(tableHeaderRow, 8).value('Lease liability').style({ bold: true });
  sheet.cell(tableHeaderRow, 9).value('Interest').style({ bold: true });
  sheet.cell(tableHeaderRow, 10).value('Payment').style({ bold: true });
  sheet.cell(tableHeaderRow, 11).value('Lease liability-Ending').style({ bold: true });
  sheet.cell(tableHeaderRow, 12).value('Principle').style({ bold: true });
  sheet.cell(tableHeaderRow, 13).value('Non-current').style({ bold: true });
  sheet.cell(tableHeaderRow, 14).value('Current').style({ bold: true });
  sheet.cell(tableHeaderRow, 15).value('Payment').style({ bold: true });
  sheet.cell(tableHeaderRow, 16).value('Depreciation').style({ bold: true });
  sheet.cell(tableHeaderRow, 17).value('Finance cost').style({ bold: true });
  sheet.cell(tableHeaderRow, 18).value('Checked').style({ bold: true });
  sheet.cell(tableHeaderRow, 19).value('EOM').style({ bold: true });
  const reportDates = getReportDates(leasePeriod.from, leasePeriod.to);
  let numOfMonth = 0;
  const tableStartRow = tableHeaderRow + 1;
  const tableEndRow = tableHeaderRow + reportDates.length;
  reportDates.forEach((rd, i) => {
    const row = tableHeaderRow + i + 1;
    if (fiscal + rd.month() === 12 && rd.date() === rd.daysInMonth()) {
      sheet.row(row).style({
        bold: true,
        fontColor: { rgb: 'ff0000' },
      });
    }
    // Date
    sheet.cell(row, 1).formula(`
    DATE(${rd.year()},${rd.month() + 1},${rd.date()})
    `).style('numberFormat', 'd/m/yyyy');

    // Financial Year
    if (i === 0) {
      sheet.cell(row, 2).value('N/A');
    } else {
      sheet.cell(row, 2).formula(`
      YEAR(DATE(YEAR(A${row}),MONTH(A${row})+(B5-1),1))
      `).style('numberFormat', '0');
    }

    // Payment
    if (i === 0) {
      sheet
        .cell(row, 3)
        .value(0)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    } else {
      const startPayDate = i === 1 ? reportDates[0] : moment(reportDates[i - 1]).add(1, 'day');
      const endPayDate = rd;
      const paymentLength = endPayDate.diff(startPayDate, 'day') + 1;
      const monthLength = endPayDate.daysInMonth();
      const formulas = [];
      for (let j = 0; j < segments.length; j += 1) {
        const seg = segments[j];
        const laterStart = startPayDate.isAfter(seg.from) ? startPayDate : seg.from;
        const earlierEnd = endPayDate.isBefore(seg.to) ? endPayDate : seg.to;
        let segLength = earlierEnd.diff(laterStart, 'day') + 1;
        if (segLength < 0) {
          segLength = 0;
        }
        if (segLength !== 0) {
          const segRow = 7 + j;
          const basePayment = paymentLength === monthLength ?
            `G${segRow}` : `G${segRow}*(${paymentLength}/${monthLength})`;
          if (segLength !== paymentLength) {
            formulas.push(`${basePayment}*(${segLength}/${paymentLength})`);
          } else {
            formulas.push(basePayment);
          }
        }
      }
      sheet
        .cell(row, 3)
        .formula(formulas.join('+'))
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    }

    // Lease Assets - Cost
    if (i === 0) {
      sheet
        .cell(row, 4)
        .formula(`
        NPV(B4,C${tableStartRow + 1}:C${tableEndRow})+C${tableStartRow}
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    } else {
      sheet
        .cell(row, 4)
        .formula(`
        D${row - 1}
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    }

    // Lease Assets - Dep
    if (i === 0) {
      sheet
        .cell(row, 5)
        .value(0)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    } else if (i === 1) {
      const daysRatio = leasePeriod.from.daysInMonth() - leasePeriod.from.date() + 1;
      numOfMonth += daysRatio / leasePeriod.from.daysInMonth();
      sheet
        .cell(row, 5)
        .formula(`
        -D${row}/B3*${daysRatio}/${leasePeriod.from.daysInMonth()}
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    } else if (i === reportDates.length - 1) {
      numOfMonth += leasePeriod.to.date() / leasePeriod.to.daysInMonth();
      sheet
        .cell(row, 5)
        .formula(`
        -D${row}/B3*${leasePeriod.to.date()}/${leasePeriod.to.daysInMonth()}
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    } else {
      numOfMonth += 1;
      sheet
        .cell(row, 5)
        .formula(`
        -D${row}/B3
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    }

    // Number of Month
    sheet.cell('B3').value(numOfMonth).style('numberFormat', '0.00');

    // Lease Assets - NBV
    if (i === 0) {
      sheet
        .cell(row, 6)
        .formula(`
        SUM(D${row}:E${row})
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    } else {
      sheet
        .cell(row, 6)
        .formula(`
        F${row - 1} + E${row}
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    }

    sheet.cell(row, 7).style({
      fill: {
        type: 'solid',
        color: '999999',
      },
    });

    // Lease liability
    if (i === 0) {
      sheet
        .cell(row, 8)
        .formula(`
        D${row}
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    } else {
      sheet
        .cell(row, 8)
        .formula(`
        K${row - 1}
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    }

    // Interest
    if (i === 0) {
      sheet
        .cell(row, 9)
        .value(0)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    } else {
      sheet
        .cell(row, 9)
        .formula(`
        H${row}*B4
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    }

    // Payment
    sheet
      .cell(row, 10)
      .formula(`
      -C${row}
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // Lease liability - Ending
    sheet
      .cell(row, 11)
      .formula(`
      SUM(H${row}:J${row})
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // Principle
    sheet
      .cell(row, 12)
      .formula(`
      -SUM(I${row}:J${row})
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // Non-current
    sheet
      .cell(row, 13)
      .formula(`
      SUMIF(A:A,">"&EOMONTH(A${row},12),L:L)
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // Current
    sheet
      .cell(row, 14)
      .formula(`
      K${row}-M${row}
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // Payment
    sheet
      .cell(row, 15)
      .formula(`
      -SUM(J${tableStartRow}:J${row})
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // Depreciation
    sheet
      .cell(row, 16)
      .formula(`
      -SUM(E${tableStartRow}:E${row})
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // Finance cost
    sheet
      .cell(row, 17)
      .formula(`
      SUM(I${tableStartRow}:I${row})
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // Checked
    sheet
      .cell(row, 18)
      .formula(`
      F${row}-K${row}-O${row}+P${row}+Q${row}
      `)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    // EOM
    sheet
      .cell(row, 19)
      .formula(`
      EOMONTH(A${row},0)
      `)
      .style('numberFormat', 'd/m/yyyy');
  });

  for (let i = 1; i <= 19; i += 1) {
    sheet.column(i).width(i === 7 ? 10 : 20);
  }
};

export const generateSummary = (input, sheet) => {
  const {
    financialYears,
    fiscal,
    leaseCodes,
  } = input;

  sheet.cell('A1').value('Fiscal Year Support');
  sheet.cell('B1').value(fiscal);
  const pfy = financialYears[0] - 1;
  sheet.cell('E1').value(`FY${financialYears[0]} Openning`);
  sheet.range('E1:J1').merged(true).style({
    horizontalAlignment: 'center',
  });
  sheet.cell('E2').formula(`DATE(${pfy}, 14-B1, 1)-1`).style('numberFormat', 'd/m/yyyy');
  const bs = {
    style: 'thick',
    color: '000000',
  };
  sheet.cell('E1').style({
    topBorder: bs,
    leftBorder: bs,
  });
  sheet.range('F1:I1').style({
    topBorder: bs,
  });
  sheet.cell('J1').style({
    topBorder: bs,
    rightBorder: bs,
  });
  sheet.cell('E2').style({
    leftBorder: bs,
    bottomBorder: bs,
  });
  sheet.range('F2:I2').style({
    bottomBorder: bs,
  });
  sheet.cell('J2').style({
    bottomBorder: bs,
    rightBorder: bs,
  });
  sheet.range('E1:J2').style({
    fill: {
      type: 'solid',
      color: 'e55934',
    },
  });
  sheet.cell('K1').value('Adjustment summary');
  sheet.cell('A3').value('Proposed entries').style({ bold: true });
  sheet.cell('E3').value('Correct position');
  sheet.range('E3:F3').merged(true).style({
    horizontalAlignment: 'center',
    bold: true,
    fill: {
      type: 'solid',
      color: '5bc0eb',
    },
  });
  sheet.cell('G3').value('Per client');
  sheet.range('G3:H3').merged(true).style({
    horizontalAlignment: 'center',
    bold: true,
    fill: {
      type: 'solid',
      color: '5bc0eb',
    },
  });
  sheet.cell('I3').value('Proposed adjustment');
  sheet.range('I3:J3').merged(true).style({
    horizontalAlignment: 'center',
    bold: true,
    fill: {
      type: 'solid',
      color: '5bc0eb',
    },
  });
  sheet.cell('K3').value('Dr. Lease assets - NBV');
  sheet.cell('L3').value('Dr/Cr. Lease liability');
  sheet.cell('M3').value('Cr. Rental deposits');
  sheet.cell('N3').value('Dr. R/E');
  sheet.cell('O3').value('Dr. Depreciation');
  sheet.cell('P3').value('Dr. finance cost');
  sheet.cell('Q3').value('Dr. Rental expenses');
  sheet.cell('R3').value('Dr./Cr. Other payable');
  sheet.cell('E4').value('Dr');
  sheet.cell('F4').value('Cr');
  sheet.cell('G4').value('Dr');
  sheet.cell('H4').value('Cr');
  sheet.cell('I4').value('Dr');
  sheet.cell('J4').value('Cr');
  sheet.range('E4:J4').style({
    bold: true,
    horizontalAlignment: 'center',
  });
  sheet.range(3, 5 + 1, leaseCodes.length * 14 + 4, 5 + 1).style({
    rightBorder: {
      style: 'thin',
      color: '000000',
    },
  });
  sheet.range(3, 5 + 3, leaseCodes.length * 14 + 4, 5 + 3).style({
    rightBorder: {
      style: 'thin',
      color: '000000',
    },
  });
  sheet.range(3, 5 + 5, leaseCodes.length * 14 + 4, 5 + 5).style({
    rightBorder: {
      style: 'thin',
      color: '000000',
    },
  });
  financialYears.forEach((fy, i) => {
    const columnStart = 19 + i * 14;
    sheet.cell(1, columnStart).value(`FY${fy}`);
    sheet.range(1, columnStart, 1, columnStart + 5).merged(true).style({
      horizontalAlignment: 'center',
    });
    sheet.cell(1, columnStart + 6).value('Adjustment summary');
    sheet
      .cell(2, columnStart)
      .formula(`DATE(${fy}, 14-B1, 1)-1`)
      .style('numberFormat', 'd/m/yyyy');
    sheet.cell(1, columnStart).style({
      topBorder: bs,
      leftBorder: bs,
    });
    sheet.range(1, columnStart + 1, 1, columnStart + 4).style({
      topBorder: bs,
    });
    sheet.cell(1, columnStart + 5).style({
      topBorder: bs,
      rightBorder: bs,
    });
    sheet.cell(2, columnStart).style({
      leftBorder: bs,
      bottomBorder: bs,
    });
    sheet.range(2, columnStart + 1, 2, columnStart + 4).style({
      bottomBorder: bs,
    });
    sheet.cell(2, columnStart + 5).style({
      bottomBorder: bs,
      rightBorder: bs,
    });
    sheet
      .range(1, columnStart, 2, columnStart + 5)
      .style({
        fill: {
          type: 'solid',
          color: 'e55934',
        },
      });
    sheet.cell(3, columnStart).value('Correct position');
    sheet.range(3, columnStart, 3, columnStart + 1).merged(true).style({
      horizontalAlignment: 'center',
      bold: true,
      fill: {
        type: 'solid',
        color: '5bc0eb',
      },
    });
    sheet.cell(3, columnStart + 2).value('Per client');
    sheet.range(3, columnStart + 2, 3, columnStart + 3).merged(true).style({
      horizontalAlignment: 'center',
      bold: true,
      fill: {
        type: 'solid',
        color: '5bc0eb',
      },
    });
    sheet.cell(3, columnStart + 4).value('Proposed adjustment');
    sheet.range(3, columnStart + 4, 3, columnStart + 5).merged(true).style({
      horizontalAlignment: 'center',
      bold: true,
      fill: {
        type: 'solid',
        color: '5bc0eb',
      },
    });
    sheet.cell(3, columnStart + 6).value('Dr. Lease assets - NBV');
    sheet.cell(3, columnStart + 7).value('Dr/Cr. Lease liability');
    sheet.cell(3, columnStart + 8).value('Cr. Rental deposits');
    sheet.cell(3, columnStart + 9).value('Dr. R/E');
    sheet.cell(3, columnStart + 10).value('Dr. Depreciation');
    sheet.cell(3, columnStart + 11).value('Dr. finance cost');
    sheet.cell(3, columnStart + 12).value('Dr. Rental expenses');
    sheet.cell(3, columnStart + 13).value('Dr./Cr. Other payable');
    sheet.cell(4, columnStart).value('Dr');
    sheet.cell(4, columnStart + 1).value('Cr');
    sheet.cell(4, columnStart + 2).value('Dr');
    sheet.cell(4, columnStart + 3).value('Cr');
    sheet.cell(4, columnStart + 4).value('Dr');
    sheet.cell(4, columnStart + 5).value('Cr');
    sheet.range(4, columnStart, 4, columnStart + 5).style({
      bold: true,
      horizontalAlignment: 'center',
    });
    sheet.range(3, columnStart + 1, leaseCodes.length * 14 + 4, columnStart + 1).style({
      rightBorder: {
        style: 'thin',
        color: '000000',
      },
    });
    sheet.range(3, columnStart + 3, leaseCodes.length * 14 + 4, columnStart + 3).style({
      rightBorder: {
        style: 'thin',
        color: '000000',
      },
    });
    sheet.range(3, columnStart + 5, leaseCodes.length * 14 + 4, columnStart + 5).style({
      rightBorder: {
        style: 'thin',
        color: '000000',
      },
    });
  });

  leaseCodes.forEach((code, i) => {
    const r = 5 + i * 14;

    // Lease Code
    sheet.cell(r, 1).value(code).style({
      bold: true,
      fontColor: 'ff0000',
    });

    // Premise
    sheet.cell(r + 1, 1).formula(`INDIRECT(A${r}&"!B1")`).style({
      bold: true,
    });

    // Contract Period
    sheet.cell(r + 2, 1).value('from');
    sheet.cell(r + 2, 2).formula(`INDIRECT(A${r}&"!B2")`).style('numberFormat', 'd/m/yyyy');
    sheet.cell(r + 2, 3).value('to');
    sheet.cell(r + 2, 4).formula(`INDIRECT(A${r}&"!C2")`).style('numberFormat', 'd/m/yyyy');

    // openings
    sheet.cell(r + 4, 2).value('BS position').style({ bold: true });
    sheet.cell(r + 4, 4).value('Dr. Lease assets - NBV');
    sheet
      .cell(r + 4, 'E')
      .formula(`IFERROR(VLOOKUP(E2,INDIRECT(A${r}&"!A:F"),6,FALSE),0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet
      .cell(r + 4, 'I')
      .formula(`IFERROR(E${r + 4},0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet
      .cell(r + 4, 'K')
      .formula(`IFERROR(I${r + 4},0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet.cell(r + 5, 4).value('Dr/Cr. Lease liability');
    sheet
      .cell(r + 5, 'F')
      .formula(`IFERROR(-VLOOKUP(E2,INDIRECT(A${r}&"!A:K"),11,FALSE),0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet
      .cell(r + 5, 'I')
      .formula(`IFERROR(E${r + 5},0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet
      .cell(r + 5, 'J')
      .formula(`IFERROR(F${r + 5},0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet
      .cell(r + 5, 'L')
      .formula(`IFERROR(IF(J${r + 5}<0,J${r + 5},0),0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet.cell(r + 6, 4).value('Dr. R/E');
    sheet
      .cell(r + 6, 'I')
      .formula(`IFERROR(IF(-SUM(I${r + 4}:J${r + 5})>0,-SUM(I${r + 4}:J${r + 5}),0),0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet
      .cell(r + 6, 'J')
      .formula(`IFERROR(IF(-SUM(I${r + 4}:J${r + 5})<0,-SUM(I${r + 4}:J${r + 5}),0),0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
    sheet
      .cell(r + 6, 'N')
      .formula(`IFERROR(I${r + 6}+J${r + 6},0)`)
      .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');

    sheet.cell(r + 7, 4).value('Dr/Cr. Other payable');
    sheet.cell(r + 9, 2).value('PL position').style({ bold: true });
    sheet.cell(r + 9, 4).value('Dr. Depreciation');
    sheet.cell(r + 10, 4).value('Dr. finance cost');
    sheet.cell(r + 11, 4).value('Dr. Rental expenses');
    sheet.cell(r + 12, 4).value('Dr/Cr. Other payable');

    sheet
      .range(r + 1, 1, r + 13, 10)
      .style({
        fill: {
          type: 'solid',
          color: '9bc53d',
        },
      });
    financialYears.forEach((fy, j) => {
      const c = (j + 1) * 14 + 5;
      const C = sheet.column(c).columnName();
      const C1 = sheet.column(c + 1).columnName();
      const C4 = sheet.column(c + 4).columnName();
      const C5 = sheet.column(c + 5).columnName();
      sheet
        .cell(r + 4, c)
        .formula(`IFERROR(VLOOKUP(${C}2,INDIRECT(A${r}&"!A:F"),6,FALSE),0)`)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 9, c)
        .formula(`IFERROR(-SUMIF(INDIRECT(A${r}&"!B:B"),"${fy}",INDIRECT(A${r}&"!E:E")),0)`)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 10, c)
        .formula(`IFERROR(SUMIF(INDIRECT(A${r}&"!B:B"),"${fy}",INDIRECT(A${r}&"!I:I")),0)`)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 5, c + 1)
        .formula(`IFERROR(-VLOOKUP(${C}2,INDIRECT(A${r}&"!A:K"),11,FALSE),0)`)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 11, c + 2)
        .formula(`IFERROR(SUMIF(INDIRECT(A${r}&"!B:B"),"${fy}",INDIRECT(A${r}&"!C:C")),0)`)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 4, c + 4)
        .formula(`IFERROR(${C}${r + 4},0)`)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 5, c + 4)
        .formula(`IFERROR(${C}${r + 5},0)`)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      if (j === 0) {
        sheet
          .cell(r + 6, c + 4)
          .formula(`IFERROR(I${r + 6},0)`)
          .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      } else {
        const lrC = sheet.column(c - 10).columnName();
        const lrC1 = sheet.column(c - 9).columnName();
        sheet
          .cell(r + 6, c + 4)
          .formula(`
          IFERROR(
            IF(
              SUM(${lrC}${r + 9}:${lrC1}${r + 11},${lrC}${r + 6}:${lrC1}${r + 6})>0,
              SUM(${lrC}${r + 9}:${lrC1}${r + 11},${lrC}${r + 6}:${lrC1}${r + 6}),0
            ),0
          )
          `)
          .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      }
      sheet
        .cell(r + 7, c + 4)
        .formula(`
        IFERROR(IF(SUM(${C4}${r + 4}:${C5}${r + 6})<0,-SUM(${C4}${r + 4}:${C5}${r + 6}),0),0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 9, c + 4)
        .formula(`
        IFERROR(${C}${r + 9},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 10, c + 4)
        .formula(`
        IFERROR(${C}${r + 10},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 12, c + 4)
        .formula(`
        IFERROR(IF(SUM(${C4}${r + 9}:${C5}${r + 11})<0,-SUM(${C4}${r + 9}:${C5}${r + 11}),0),0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 5, c + 5)
        .formula(`
        IFERROR(${C1}${r + 5},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      if (j === 0) {
        sheet
          .cell(r + 6, c + 5)
          .formula(`
          IFERROR(J${r + 6},0)
          `)
          .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      } else {
        const lrC = sheet.column(c - 10).columnName();
        const lrC1 = sheet.column(c - 9).columnName();
        sheet
          .cell(r + 6, c + 5)
          .formula(`
          IFERROR(
            IF(
              SUM(${lrC}${r + 9}:${lrC1}${r + 11},${lrC}${r + 6}:${lrC1}${r + 6})<0,
              SUM(${lrC}${r + 9}:${lrC1}${r + 11},${lrC}${r + 6}:${lrC1}${r + 6}),0
            ),0
          )
          `)
          .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      }
      sheet
        .cell(r + 7, c + 5)
        .formula(`
        IFERROR(IF(SUM(${C4}${r + 4}:${C5}${r + 6})>0,-SUM(${C4}${r + 4}:${C5}${r + 6}),0),0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 7, c + 12)
        .formula(`
        IFERROR(IF(SUM(${C4}${r + 9}:${C5}${r + 11})>0,-SUM(${C4}${r + 9}:${C5}${r + 11}),0),0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      // adjustment summary
      sheet
        .cell(r + 4, c + 6)
        .formula(`
        IFERROR(${C4}${r + 4},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 5, c + 7)
        .formula(`
        IFERROR(IF(${C5}${r + 5}<0,${C5}${r + 5},0),0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 6, c + 9)
        .formula(`
        IFERROR(${C4}${r + 6}+${C5}${r + 6},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 9, c + 10)
        .formula(`
        IFERROR(${C4}${r + 9}+${C5}${r + 9},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 10, c + 11)
        .formula(`
        IFERROR(${C4}${r + 10}+${C5}${r + 10},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 11, c + 12)
        .formula(`
        IFERROR(${C4}${r + 11}+${C5}${r + 11},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 7, c + 13)
        .formula(`
        IFERROR(${C4}${r + 7}+${C5}${r + 7},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .cell(r + 12, c + 13)
        .formula(`
        IFERROR(${C4}${r + 12}+${C5}${r + 12},0)
        `)
        .style('numberFormat', '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)');
      sheet
        .range(r + 1, c, r + 13, c + 5)
        .style({
          fill: {
            type: 'solid',
            color: '9bc53d',
          },
        });
    });
  });

  const colCount = 4 + (financialYears.length + 1) * 14;
  for (let i = 1; i <= colCount; i += 1) {
    sheet.column(i).width(20);
  }
};
