import React, { Component } from 'react';
import {
  Card,
  Button,
  Tabs,
  Icon,
  Typography,
  Layout,
  Select,
  Row,
  Col,
  InputNumber,
  Alert,
} from 'antd';
import xlsx from 'xlsx-populate';
import moment from 'moment';

import './App.css';
import wechat from './wechat.jpg';
import payme from './payme.jpg';
import SampleLeaseForm from './SampleLeaseForm';
import LeaseForm from './LeaseForm';
import { generatePaymentSchedule, generateSummary } from './utils';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;
const { Header, Sider, Content } = Layout;
const { Option } = Select;

let id = 1;

class App extends Component {
  constructor(props) {
    super(props);
    const thisYear = moment().year();
    this.state = {
      leases: [`P${id}`],
      activePane: `P${id}`,
      fiscal: 10,
      from: thisYear - 2,
      to: thisYear,
    };
    this.forms = [];
  }

  handleAddLease = () => {
    const { leases } = this.state;
    id += 1;
    this.setState({
      leases: [...leases, `P${id}`],
      activePane: `P${id}`,
    });
  }

  handlePaneChange = (active) => {
    this.setState({ activePane: active });
  }

  handleFormReady = (code, form) => {
    this.forms.push({ code, form });
  }

  handleFromChange = (value) => {
    const { to } = this.state;
    this.setState({ from: value });
    if (to < value) {
      this.setState({ to: to + 1 });
    }
  }

  handleToChange = (value) => {
    const { from } = this.state;
    this.setState({ to: value });
    if (from > value) {
      this.setState({ from: from - 1 });
    }
  }

  handleFiscalChange = (value) => {
    this.setState({ fiscal: value });
  }

  handleGenerate = async () => {
    const { fiscal, from, to } = this.state;
    let hasError = false;
    const inputs = this.forms.map(({ code, form }) => {
      const values = form.getFieldsValue();
      if (!values.leasePeriod) {
        hasError = true;
        return {};
      }
      const input = {
        premise: values.premise,
        borrowRate: values.borrowRate,
        fiscal,
        leasePeriod: {
          from: values.leasePeriod[0].endOf('day'),
          to: values.leasePeriod[1].endOf('day'),
        },
        segments: values.segments.map(k => ({
          from: values.segmentPeriod[k][0].endOf('day'),
          to: values.segmentPeriod[k][1].endOf('day'),
          rent: values.rent[k],
          mgtFee: values.mgtFee[k],
          promotionLevy: values.promotionLevy[k],
        })),
      };
      return { code, input };
    });
    // const inputs = [{
    //   code: 'P1',
    //   input: {
    //     fiscal,
    //     premise: 'Unit Nos. 1002-3 on 10/F, Omega Plaza, 32-34A Dundas Street, Mongkok, Kowloon',
    //     borrowRate: 0.29,
    //     leasePeriod: {
    //       from: moment('2016-05-03').endOf('day'),
    //       to: moment('2018-04-16').endOf('day'),
    //     },
    //     segments: [
    //       {
    //         from: moment('2016-05-03').endOf('day'),
    //         to: moment('2016-06-02').endOf('day'),
    //         rent: 0,
    //         mgtFee: 8723,
    //         promotionLevy: 0,
    //       },
    //       {
    //         from: moment('2016-06-03').endOf('day'),
    //         to: moment('2017-05-02').endOf('day'),
    //         rent: 37110,
    //         mgtFee: 8723,
    //         promotionLevy: 0,
    //       },
    //       {
    //         from: moment('2017-05-03').endOf('day'),
    //         to: moment('2018-04-16').endOf('day'),
    //         rent: 0,
    //         mgtFee: 38956,
    //         promotionLevy: 8723,
    //       },
    //     ],
    //   },
    // }];
    if (hasError) {
      this.setState({ showError: true });
      return;
    }
    const wb = await xlsx.fromBlankAsync();
    const financialYears = [];
    for (let i = from; i <= to; i += 1) {
      financialYears.push(i);
    }
    inputs.forEach(({ code, input }) => {
      const ws = wb.addSheet(code);
      generatePaymentSchedule(input, ws);
    });
    const ws = wb.addSheet('Summary');
    const summaryInput = {
      financialYears,
      fiscal,
      leaseCodes: inputs.map(c => c.code),
    };
    generateSummary(summaryInput, ws);

    // Delete the default sheet
    wb.deleteSheet(0);

    // Save workbook
    const blob = await wb.outputAsync();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = 'out.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  render = () => {
    const { leases, activePane, fiscal, from, to, showError } = this.state;
    const operations = (
      <div>
        <Button style={{ margin: 5 }} type='dashed' onClick={this.handleAddLease}>
          <Icon type='plus' />
          Add New Contract
        </Button>
        <Button style={{ margin: 5 }} type='primary' onClick={this.handleGenerate}>
          Generate
        </Button>
      </div>
    );
    const panes = leases.map(l => (
      <TabPane tab={l} key={l} style={{ padding: 10 }}>
        <LeaseForm code={l} onFormReady={this.handleFormReady} />
      </TabPane>
    ));
    panes.unshift(
      <TabPane tab='sample' key='sample' style={{ padding: 10 }}>
        <SampleLeaseForm />
      </TabPane>,
    );
    return (
      <Layout>
        <Layout>
          {showError &&
            <Alert
              style={{ margin: 10 }}
              message='Contract period is missing!'
              type='error'
              banner
              showIcon
              closable
              onClose={() => { this.setState({ showError: false }); }}
            />
          }
          <Header style={{ background: 'none' }}>
            <Title level={1}>HKFRS 16 - Lease Quantification Tool</Title>
          </Header>
          <Content>
            <Row style={{ margin: 10 }}>
              <Col span={3} style={{ textAlign: 'center' }}>
                <Title level={4}>from</Title>
              </Col>
              <Col span={3}>
                <InputNumber value={from} onChange={this.handleFromChange} />
              </Col>
              <Col span={3} style={{ textAlign: 'center' }}>
                <Title level={4}>to</Title>
              </Col>
              <Col span={3}>
                <InputNumber value={to} onChange={this.handleToChange} />
              </Col>
              <Col span={3} style={{ textAlign: 'center' }}>
                <Title level={4}>Y.E.</Title>
              </Col>
              <Col span={6}>
                <Select
                  value={fiscal}
                  placeholder='End of Financial Year'
                  style={{ width: '100%' }}
                  onChange={this.handleFiscalChange}
                >
                  <Option value={12}>31/1</Option>
                  <Option value={11}>28(29)/2</Option>
                  <Option value={10}>31/3</Option>
                  <Option value={9}>30/4</Option>
                  <Option value={8}>31/5</Option>
                  <Option value={7}>30/6</Option>
                  <Option value={6}>31/7</Option>
                  <Option value={5}>31/8</Option>
                  <Option value={4}>30/9</Option>
                  <Option value={3}>31/10</Option>
                  <Option value={2}>30/11</Option>
                  <Option value={1}>31/12</Option>
                </Select>
              </Col>
            </Row>
            <Row>
              <Tabs
                type='card'
                tabBarExtraContent={operations}
                activeKey={activePane}
                onChange={this.handlePaneChange}
              >
                {panes}
              </Tabs>
            </Row>

          </Content>
        </Layout>
        <Sider
          theme='light'
          width='40%'
        >
          <Paragraph strong>
            we are:
          </Paragraph>
          <Paragraph strong>
            An auditor+ A programer
          </Paragraph>
          <Paragraph strong>
            Fighting for work-life balance!!!
          </Paragraph>
          <Paragraph strong>
            Please donate if you would like to support.
          </Paragraph>
          <Card
            style={{ width: 200 }}
            cover={<img alt='please support' src={wechat} />}
          />
          <Card
            style={{ width: 200 }}
            cover={<img alt='please support' src={payme} />}
          />
        </Sider>
      </Layout>
    );
  }
}

export default App;
