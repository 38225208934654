import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

class DateRange extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      // eslint-disable-next-line
      const value = nextProps.value || [undefined, undefined];
      return {
        startValue: value[0],
        endValue: value[1],
      };
    }
    return null;
  }

  static propTypes = {
    disabled: PropTypes.bool,
    onlyEnd: PropTypes.bool,
    range: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
  }

  static defaultProps = {
    disabled: false,
    onlyEnd: false,
    range: undefined,
  }

  constructor(props) {
    super(props);
    // eslint-disable-next-line
    const value = props.value || [undefined, undefined];
    this.state = {
      startValue: value[0],
      endValue: value[1],
      endOpen: false,
    };
  }

  disabledStartDate = (startValue) => {
    const { range } = this.props;
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    const startEndLimit = startValue.valueOf() > endValue.valueOf();
    const rangeLimit = range && startValue.valueOf() < range[0].valueOf();
    return startEndLimit || rangeLimit;
  };

  disabledEndDate = (endValue) => {
    const { range } = this.props;
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    const startEndLimit = endValue.valueOf() <= startValue.valueOf();
    const rangeLimit = range && endValue.valueOf() > range[1].valueOf();
    return startEndLimit || rangeLimit;
  };

  onChange = (field, newValue) => {
    // eslint-disable-next-line
    const { onChange } = this.props;
    const { startValue, endValue } = this.state;
    if (field === 'startValue') {
      onChange([newValue.endOf('day'), moment(endValue)]);
    } else if (field === 'endValue') {
      onChange([moment(startValue), newValue.endOf('day')]);
    }
  };

  onStartChange = (value) => {
    if (!('value' in this.props)) {
      this.setState({ startValue: value.endOf('day') });
    }
    this.onChange('startValue', value.endOf('day'));
  };

  onEndChange = (value) => {
    if (!('value' in this.props)) {
      this.setState({ endValue: value.endOf('day') });
    }
    this.onChange('endValue', value.endOf('day'));
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  render() {
    const { disabled, onlyEnd } = this.props;
    const { startValue, endValue, endOpen } = this.state;
    return (
      <div>
        <DatePicker
          disabled={disabled || onlyEnd}
          disabledDate={this.disabledStartDate}
          format='YYYY-MM-DD'
          value={startValue}
          placeholder='Start'
          onChange={this.onStartChange}
          onOpenChange={this.handleStartOpenChange}
          style={{ marginRight: 5 }}
        />
        <DatePicker
          disabled={disabled}
          disabledDate={this.disabledEndDate}
          format='YYYY-MM-DD'
          value={endValue}
          placeholder='End'
          onChange={this.onEndChange}
          open={endOpen}
          onOpenChange={this.handleEndOpenChange}
        />
      </div>
    );
  }
}

export default DateRange;
