import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Typography,
  InputNumber,
  Button,
  Icon,
  Row,
  Col,
  Divider,
} from 'antd';
import moment from 'moment';

import DateRange from './DateRange';

const { Title, Paragraph } = Typography;

class LeaseForm extends Component {
  static propTypes = {
    code: PropTypes.string.isRequired,
    onFormReady: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.id = 0;
  }

  componentDidMount = () => {
    // eslint-disable-next-line
    const { form, onFormReady, code } = this.props;
    onFormReady(code, form);
  }

  canAddSegment = () => {
    // eslint-disable-next-line
    const { form } = this.props;
    const leasePeriod = form.getFieldValue('leasePeriod');
    const segmentPeriod = form.getFieldValue('segmentPeriod');
    const segments = form.getFieldValue('segments');
    if (!leasePeriod) {
      return false;
    }
    const lastSegEnd = segmentPeriod[segments.length - 1][1];
    return lastSegEnd.valueOf() < leasePeriod[1].valueOf();
  }

  add = () => {
    // eslint-disable-next-line
    const { form } = this.props;
    const segments = form.getFieldValue('segments');
    const nextKeys = segments.concat(this.id);
    this.id += 1;
    form.setFieldsValue({
      segments: nextKeys,
    });
  }

  handleLeasePeriod = (dates) => {
    // eslint-disable-next-line
    const { form } = this.props;
    const { getFieldValue } = form;
    const segments = getFieldValue('segments');
    if (segments.length === 0) {
      const nextKeys = segments.concat(this.id);
      this.id += 1;
      form.setFieldsValue({
        segments: nextKeys,
      });
    } else {
      form.setFieldsValue({
        segments: [0],
        segmentPeriod: [dates],
      });
      this.id = 1;
    }
  }

  handleRemoveSegment = () => {
    // eslint-disable-next-line
    const { form } = this.props;
    const segments = form.getFieldValue('segments');
    form.setFieldsValue({
      segments: segments.slice(0, segments.length - 1),
    });
    this.id -= 1;
  }

  initialSegmentValue = (k) => {
    // eslint-disable-next-line
    const { getFieldValue } = this.props.form;
    const leasePeriod = getFieldValue('leasePeriod');
    if (!leasePeriod) {
      return undefined;
    }
    if (k === 0) {
      return [moment(leasePeriod[0]), moment(leasePeriod[1])];
    }
    const segmentPeriods = getFieldValue('segmentPeriod');
    const last = segmentPeriods[k - 1];
    if (last) {
      return [moment(last[1]).add(1, 'day'), moment(leasePeriod[1])];
    }
    return undefined;
  }

  render = () => {
    // eslint-disable-next-line
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('segments', { initialValue: [] });
    const segments = getFieldValue('segments');
    const segmentItems = segments.map((k, i) => (
      <React.Fragment key={k}>
        <Col span={20}>
          <Title level={3}>{`Rental Period ${k + 1}`}</Title>
        </Col>
        <Col span={2}>
          {i === segments.length - 1 && i !== 0 &&
            <Button type='danger' onClick={this.handleRemoveSegment}>Remove</Button>
          }
        </Col>
        <Col span={24}>
          <Form.Item label='Period'>
            {getFieldDecorator(`segmentPeriod[${k}]`, { initialValue: this.initialSegmentValue(k) })(
              <DateRange onlyEnd range={getFieldValue('leasePeriod')} />,
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label='Rent'>
            {getFieldDecorator(`rent[${k}]`)(
              <InputNumber />,
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label='Management Fee'>
            {getFieldDecorator(`mgtFee[${k}]`)(
              <InputNumber />,
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label='Other Fixed Payment'>
            {getFieldDecorator(`promotionLevy[${k}]`)(
              <InputNumber />,
            )}
          </Form.Item>
        </Col>
        <Divider />
      </React.Fragment>
    ));
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    const contractPeriodHelpText = (
      <Typography>
        <Paragraph type='secondary'>
          The whole period that the contract covers, including rental-free period.
        </Paragraph>
        <Paragraph type='secondary'>
          {'If there are periods with different rental, please update in "rental period".'}
        </Paragraph>
        <Paragraph type='secondary'>
          E.g. Rental Period 1, rent = 0 to reflect rental free period.
        </Paragraph>
        <Paragraph type='secondary'>
          Rental period 2, rent = 10k
        </Paragraph>
        <Paragraph type='secondary'>
          Rental period 3, rent = 20k etc.
        </Paragraph>
      </Typography>
    );
    return (
      <Form {...formItemLayout} className='lease-form'>
        <Row>
          <Title level={3}>Contract Information</Title>
          <Form.Item label='Premise' help='Please insert the address'>
            {getFieldDecorator('premise')(
              <Input
                placeholder='premise'
                style={{ width: 500 }}
              />,
            )}
          </Form.Item>
          <Form.Item label='Contract Period' help={contractPeriodHelpText}>
            {getFieldDecorator('leasePeriod')(
              <DateRange onChange={this.handleLeasePeriod} />,
            )}
          </Form.Item>
          <Form.Item label='Monthly Discount Rate' help='Please assess yourself'>
            {getFieldDecorator('borrowRate', { initialValue: 0.29 })(
              <InputNumber
                placeholder='borrow rate'
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
              />,
            )}
          </Form.Item>
        </Row>
        <Row>
          Please provide rent information by adding each rental period
        </Row>
        <Divider />
        <Row gutter={24}>
          {segmentItems}
        </Row>
        <Row>
          <Form.Item>
            <Button
              type='dashed'
              onClick={this.add}
              disabled={!this.canAddSegment()}
            >
              <Icon type='plus' />
              Add Rental Period
            </Button>
          </Form.Item>
        </Row>
        <Divider />
      </Form>
    );
  }
}

const WrappedForm = Form.create({ name: 'lease' })(LeaseForm);
export default WrappedForm;
